import { Container } from 'components/Containers'
import { MbIcon, EIconName, MbText } from 'mintbase-ui'

export const ErrorPage = () => (
  <Container>
      <div className="my-52 w-full flex flex-col items-center justify-center gap-12">
        <MbIcon
          name={EIconName.ERROR}
          size="50px"
          color="error-300"
          darkColor="error-100"
        />

        <MbText className="h3-130 text-center font-bold dark:text-white text-black m-4">
          Page Not Found
        </MbText>
        <MbText className="p-big-90 text-center dark:text-gray-300 text-gray-700 leading-6">
          This page does not seem to exist. <br />Need help? Contact support (links below) and let us know.
        </MbText>
      </div>
    </Container>
)

export default ErrorPage